


















































































































































import LayoutWithPageHeader from "@/components/layout/LayoutWithPageHeader.vue";
import FilterTable from "@/components/table/FilterTable.vue";
import WorkspaceLogo from "@/components/layout/WorkspaceLogo.vue";
import AvatarGroup from "@/components/avatar/AvatarGroup.vue";
import NavLinkExternal from "@/components/navbar/NavLinkExternal.vue";

import mixins from "vue-typed-mixins";
import filterTableMixin from "@/mixins/filterTable";
import PlanSelect from "@/components/form/PlanSelect.vue";
import { FilteredWorkspace, WorkspaceFilter } from "@/models/workspace";
import { ActionTypes } from "@/store/action-types";
import { OrganizationWorkspace, Status } from "@/generated/graphql";
import { Option } from "@/components/form/EnumSelect.vue";
import { toOptions } from "@/enum";
import { confirmModal } from "@/modal";
import { required } from "vuelidate/lib/validators";
import { EnhancedPlan } from "@/models/organization";
import { RouteName } from "@/router";
import { BIconStarFill, BIconExclamationOctagon } from "bootstrap-vue";

const defaultFilter = {
  name: "",
  price: null,
  status: "ACTIVE"
};
export default mixins(
  filterTableMixin<WorkspaceFilter>(defaultFilter, ["price", "status"])
).extend({
  components: {
    LayoutWithPageHeader,
    FilterTable,
    WorkspaceLogo,
    AvatarGroup,
    PlanSelect,
    BIconStarFill,
    BIconExclamationOctagon,
    NavLinkExternal
  },
  created() {
    console.log("Created");
    this.$storeTyped.dispatch(ActionTypes.FETCH_PLANS);
  },
  destroyed() {
    console.log("Destroyed");
    this.$storeTyped.dispatch(ActionTypes.RESET_WORKSPACES);
  },
  watch: {
    workspaces: {
      handler(ws: FilteredWorkspace[] | null) {
        if (ws) {
          this.statusSwitchesMap = ws.reduce(
            (acc, val) => ({ ...acc, [val.id]: val.status === Status.Active }),
            {} as { [key: string]: boolean }
          );
        }
      },
      immediate: true
    }
  },
  computed: {
    plans(): EnhancedPlan[] {
      return this.$storeTyped.getters.getPlans;
    },
    workspaces(): FilteredWorkspace[] | null {
      console.log("====================================");
      console.log(
        "this.$storeTyped.getters.getFilteredWorkspaces",
        this.$storeTyped.getters.getFilteredWorkspaces
      );
      console.log("====================================");
      return this.$storeTyped.getters.getFilteredWorkspaces;
    },
    planOptions(): Option<string | null>[] {
      const plans: Option<string>[] = this.plans
        ? this.plans.map(p => ({ value: p.id, text: p.name }))
        : [];
      return [{ value: null, text: "All plans" }, ...plans];
    },
    statusOptions(): Option<string | null>[] {
      return [{ value: null, text: "All status" }, ...toOptions(Status)];
    },
    hasNextPage(): boolean {
      return this.$storeTyped.state.workspace.filteredWorkspaces.hasNextPage;
    },
    getStatusOrganization() {
      const currentStatus = this.$storeTyped.state.organization.organization
        .status;
      if (currentStatus == "ACTIVE") {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      selectPlanModalId: "select-plan-modal",
      selectedPlan: null as string | null,
      statusSwitchesMap: {} as { [key: string]: boolean },
      activatingWorkspace: null as OrganizationWorkspace | null,
      isFetchingWs: false,
      fields: [
        { key: "name", label: "Name" },
        { key: "plan", label: "Plan" },
        { key: "users", label: "Users" },
        { key: "status", label: "Active" }
      ],
      Status: null,
      isActiveOpenWorkspace: true
    };
  },
  methods: {
    gotoEditRoute(id: string, plan: string) {
      if (plan === "Employee Advocacy") {
        localStorage.ea = true;
      } else {
        localStorage.ea = false;
      }
      this.$root.$emit("localstorageEA", localStorage.getItem("ea"));

      this.$router.push({
        name: RouteName.WORKSPACE_ACCOUNT,
        params: {
          orgId: this.$route.params.orgId,
          id: id
        }
      });
    },

    gotoExternalWorkspace(ws: OrganizationWorkspace) {
      // Get current workspace
      const currentWs = ws.id;
      const baseUrl = process.env.VUE_APP_WEBAPP;
      const url = "http://app.stent.io/";
      if (baseUrl && baseUrl != "undefined") {
        window.open(`${baseUrl}${currentWs}`, "_blank");
      } else {
        window.open(`${url}${currentWs}`, "_blank");
      }
    },
    // custom hook from filter mixin
    onFilterChange(filter: WorkspaceFilter, loadMore = false) {
      console.log("filter", filter);
      this.isFetchingWs = true;
      this.$storeTyped
        .dispatch(ActionTypes.FILTER_WORKSPACES, {
          filter,
          loadMore
        })
        .finally(() => (this.isFetchingWs = false));
    },
    loadMore() {
      this.onFilterChange(this.filter, true);
    },
    isArchived(ws: OrganizationWorkspace) {
      return ws.status === Status.Archive;
    },
    editWorkspace(ws: OrganizationWorkspace) {
      if (ws.subscription?.plan.name == "Employee Advocacy") {
        localStorage.ea = true;
      } else {
        localStorage.ea = false;
      }
      this.$root.$emit("localstorageEA", localStorage.getItem("ea"));

      this.$router.push({
        name: RouteName.WORKSPACE_ACCOUNT,
        params: {
          orgId: this.$route.params.orgId,
          id: ws.id
        }
      });
    },
    toggleVisibility(id: string, isFavorite: boolean) {
      this.$storeTyped.dispatch(ActionTypes.TOGGLE_WORKSPACE_VISIBILITY, {
        id,
        isFavorite
      });
    },
    async activateWorkspace() {
      await this.$storeTyped.dispatch(ActionTypes.ACTIVATE_WORKSPACE, {
        wsId: this.activatingWorkspace?.id,
        priceId: this.selectedPlan
      });
      await this.onFilterChange(this.filter);
      this.activatingWorkspace = null;
      this.selectedPlan = null;
    },
    async resetSwitch() {
      if (this.activatingWorkspace) {
        this.statusSwitchesMap[this.activatingWorkspace.id] = false;
      }
      this.selectedPlan = null;
      this.activatingWorkspace = null;
    },
    async toggleWorkspaceStatus(item: OrganizationWorkspace) {
      if (item.status === Status.Active) {
        await this.$storeTyped.dispatch(ActionTypes.STOP_WORKSPACE, item.id);
      } else {
        this.activatingWorkspace = item;
        this.$bvModal.show(this.selectPlanModalId);
      }
    },
    async unarchiveWorkspace(item: OrganizationWorkspace) {
      await this.$storeTyped.dispatch(ActionTypes.STOP_WORKSPACE, item.id);
      await this.onFilterChange(this.filter);
    },
    async archiveWorkspace(item: OrganizationWorkspace) {
      if (item.status !== Status.Archive) {
        const confirmFn = async () => {
          await this.$storeTyped.dispatch(
            ActionTypes.ARCHIVE_WORKSPACE,
            item.id
          );
          await this.$storeTyped.dispatch(ActionTypes.FILTER_WORKSPACES, {
            filter: this.filter
          });
        };
        await confirmModal(
          this,
          confirmFn,
          "Please confirm you want to archive this workspace.",
          "Workspace Archiving"
        );
      }
    }
  },

  validations: {
    selectedPlan: {
      required
    }
  }
});
